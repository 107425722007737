<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <!--TITLE-->
        <VocabularyTitle :title="$t('sideBar.agencies')" :per="paginationData.per_page" :entries="paginationData.total" />
        <!--TITLE-->
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between mt-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
            <!-- <b-form-checkbox v-if="canRestore" @input="pageClick(1)" v-model="filterShowTrashed" class="mt-2" switch>
              {{$t('table.showOnlyTrashed')}}
            </b-form-checkbox> -->
          </div>
          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>

        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="table-responsive vocabulary-table">
        <table class="table table-hover columns-resizable">
          <thead>
            <tr>
              <th scope="col">
                <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
                {{ $t('sideBar.agencies') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="agenciesStatus !== 'success'">
              <td><SpinnerLoader :loading="agenciesStatus" /></td>
            </tr>
            <template v-else>
              <tr v-for="(row, index) in tableData" :key="index">
                <td>
                  <div class="d-flex w-100 flex-fill">
                    <template v-if="!row.deleted_at">
                      <button v-if="canEdit" type="button" class="btn-icon" @click="showModalAgencies('edit', row.id)">
                        <b-icon icon="pencil"></b-icon>
                        {{ $t('table.edit') }}
                      </button>
                      <router-link v-if="canViewUsers" :to="`users?agency_id=${row.id}`" class="btn-icon">
                        <b-icon icon="people"></b-icon>
                        {{ $t('sideBar.users') }}
                      </router-link>
                      <button v-if="canDelete" type="button" class="btn-icon mr-4" @click="showModalDeleteAgencies(row)">
                        <b-icon icon="trash"></b-icon>
                        {{ $t('table.delete') }}
                      </button>
                    </template>
                    <button
                      v-else-if="row.deleted_at && canRestore"
                      class="btn-icon"
                      :title="$t('table.restore')"
                      @click="showModalRestoreEntity(row, 'PUT_RESTORE_AGENCIES', $t('table.restoreAgency'))"
                    >
                      <b-icon icon="plus"></b-icon>
                    </button>
                    {{ row.name }}
                  </div>
                </td>
              </tr>
              <tr v-if="agenciesStatus === 'success' && !tableData.length">
                <td>
                  <div class="d-flex w-100 justify-content-center">
                    {{ $t('table.noContent') }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="$checkPermissions('agency.create')" variant="primary" data-automatic="add-agency-btn" @click="showModalAgencies('add')">
          {{ $t('table.addNewAgency') }}
        </b-button>
      </div>
      <!--FOOTER-->

      <b-modal
        id="add-edit-agencies"
        ref="add-edit-agencies"
        size="xl"
        :title="modalType === 'add' ? $t('table.addNewAgency') : $t('table.editAgencies')"
        :ok-disabled="!$v.writeTitle.required"
        :ok-title="modalType === 'add' ? $t('table.add') : $t('table.edit')"
        :cancel-title="$t('table.cancel')"
        :busy="isModalBusy"
        @ok.prevent="checkIfValidThenEnter"
      >
        <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
        <form v-else @submit.stop.prevent="checkIfValidThenEnter">
          <input type="submit" value="Submit" class="hidden-submit" />
          <b-form-group :label="$t('table.writeTitle')" label-for="input-agency-name">
            <b-form-input
              id="input-agency-name"
              v-model.trim="$v.writeTitle.$model"
              type="text"
              :placeholder="$t('table.enterTitle')"
              autofocus
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('table.selectAdvertisers')">
            <CoupleSelects
              :add-element.sync="selectAdd"
              :delete-element.sync="selectDelete"
              :add-options="options"
              :remove-options="value"
              @leftHandler="leftHandler()"
              @rightHandler="rightHandler()"
            />
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import SortArrows from '../components/SortArrows';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import CoupleSelects from '@/components/CoupleSelects.vue';

export default {
  name: 'Agencies',
  components: { PaginateWrapper, SpinnerLoader, VocabularyTitle, SearchDropdown, SearchInput, SortArrows, CoupleSelects },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
  },
  data() {
    return {
      tableData: [],
      paginationData: '',
      searchElements: '',
      writeTitle: '',
      value: [],
      options: [],
      filterPage: 1,
      selectedPerPage: '100',
      selectAdd: '',
      selectDelete: '',
      modalType: 'add',
      isModalLoading: true,
      isModalBusy: false,
      filterShowTrashed: false,
    };
  },
  validations: {
    writeTitle: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      agencies: 'getAgencies',
      agenciesStatus: 'getAgenciesStatus',
      advertiser: 'getAdvertisers',
      advertisersStatus: 'getAdvertisersStatus',
      modalEditAgencies: 'getModalEditAgencies',
    }),
    canEdit: function () {
      return this.$checkPermissions('agency.update');
    },
    canDelete: function () {
      return this.$checkPermissions('agency.delete');
    },
    canViewUsers: function () {
      return this.$checkPermissions('user.read_list');
    },
    canRestore() {
      return this.$checkPermissions('agency.restore');
    },
  },
  watch: {
    agencies() {
      this.setTableData();
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.agencies') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getAgenciesInfo();
  },
  destroyed() {
    this.$store.commit('clearAgenciesList');
    this.$store.commit('clearAdvertisersList');
  },
  methods: {
    leftHandler() {
      if (this.selectAdd.id) {
        this.options = this.options.filter((option) => option.id !== this.selectAdd.id);
        this.value.unshift(this.selectAdd);
        this.selectAdd = '';
      }
    },
    rightHandler() {
      if (this.selectDelete.id) {
        this.value = this.value.filter((option) => option.id !== this.selectDelete.id);
        this.options.unshift(this.selectDelete);
        this.selectDelete = '';
      }
    },

    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_AGENCIES', {
        page: +this.filterPage,
        per_page: this.selectedPerPage,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
        'filter[trashed]': this.filterShowTrashed ? 'only' : undefined,
      });
      this.updateQuery();
    },

    // pagination
    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },
    // get table info
    setTableData() {
      if (this.agencies) {
        this.tableData = this.agencies.data;
        this.paginationData = this.agencies.pagination;
        if (this.filterPage > this.agencies.pagination.last_page) {
          this.filterPage = this.agencies.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getAgenciesInfo() {
      await this.restoreFromQuery();
      this.paramsData();
    },

    async showModalAgencies(type, data) {
      this.isModalLoading = true;
      this.writeTitle = '';
      this.value = [];
      this.options = [];
      this.modalType = type;
      this.$bvModal.show('add-edit-agencies');
      await this.$store.dispatch('GET_ADVERTISERS', { per_page: 2000 });
      if (type === 'add') {
        this.options = this.advertiser.data;
      } else {
        await this.$store.dispatch('GET_AGENCIES_ID', data);
        this.options = this.advertiser.data.filter((option) => !this.modalEditAgencies.advertisers.find((value) => value.id === option.id));
        this.writeTitle = this.modalEditAgencies.name;
        this.value = this.modalEditAgencies.advertisers;
      }
      this.isModalLoading = false;
    },

    async addEditAgencies() {
      this.isModalBusy = true;
      const name = this.writeTitle;
      const formData = {
        id: this.modalType === 'add' ? undefined : this.modalEditAgencies.id,
        name: this.writeTitle,
        advertisers: this.value.map((v) => v.id),
      };
      await this.$store.dispatch(this.modalType === 'add' ? 'POST_AGENCIES' : 'PUT_AGENCIES', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalType === 'add' ? this.$i18n.t('alert.addAgency') : this.$i18n.t('alert.editAgency'),
            text: name,
          });
          this.$bvModal.hide('add-edit-agencies');
          this.paramsData();
          this.writeTitle = '';
          this.value = [];
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isModalBusy = false;
    },

    // modal delete
    async showModalDeleteAgencies(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteAgency')} "${data.name}"?`, {
          title: this.$i18n.t('table.deleteAgencies'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data.id;
            const name = data.name;
            this.$store.dispatch('DELETE_AGENCIES_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteAgency'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => {
                errorsHandler(errors, this.$notify);
              },
            });
          }
        })
        .catch((err) => {});
    },

    checkIfValidThenEnter() {
      if (this.$v.writeTitle.required && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalType === 'add'
              ? `${this.$i18n.t('table.confirmAddAgency')} "${this.writeTitle}"?`
              : `${this.$i18n.t('table.confirmEditAgency')} "${this.writeTitle}"?`,
            {
              title: this.modalType === 'add' ? this.$i18n.t('table.addAgency') : this.$i18n.t('table.editAgency'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-add-edit-agency-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditAgencies();
          })
          .catch((err) => {});
      }
    },
  },
};
</script>

<style lang="sass"></style>
